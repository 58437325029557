import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Skeleton from "react-loading-skeleton";
// import Swal from "sweetalert2";
// import { default as ReactSelect } from "react-select";
import { Icon } from "@iconify/react";
import Dropzone from "react-dropzone";
import ReactTooltip from "react-tooltip";

//Components
// import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import NumberInput from "../../../components/Input/NumberInput/NumberInput";

//Actions
import * as actions from "../../../store/ducks/deposit/actions";
import MobileSelectRadio from "../../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";

import PromosTncModal from "../../../components/PromosTncModal/PromosTncModal";

const depositAmts = [{ value: 30 }, { value: 50 }, { value: 100 }, { value: 300 }, { value: 500 }, { value: 1000 }, { value: 5000 }];

class MobileBankTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelectRadio: false,
        };
    }

    shouldComponentUpdate() {
        return true;
    }

    toggleSelectRadio = () => {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <PromosTncModal {...this.state} {...this.props} toggleTncModalHandler={this.props.toggleTncModalHandler} />
                <form className="forms-col needs-validation" method="post" id="deposit_form" encType="multipart/form-data" onSubmit={this.props.confirmHandler} noValidate>
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t('deposit.payment-option')}:</label>
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.paymentGateway.length > 0 ? (
                                                this.props.paymentGateway.map((val, idx) => {
                                                    if (val.transaction_slug === "bank-transfer" && val.dp_status === 1) {
                                                        let slug = (t(`payment-type.${val.slug}`) !== `payment-type.${val.slug}`) ? t(`payment-type.${val.slug}`) : val.slug.replaceAll('_', ' ');
                                                        return (
                                                            <div className="form-check form-check-inline four-check" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    id={`payment${val.slug}`}
                                                                    value={val.slug}
                                                                    checked={this.props.input.paymentGatewaySelected === val.slug ? true : false}
                                                                    onChange={() => { }}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.props.selectPaymentGatewayHandler(val)}>
                                                                    <img className="active" src={this.props.payment[`${val.slug}.svg`]} alt={val.slug} />
                                                                    <p>{slug}</p>
                                                                    {val.deposit_est_time && (
                                                                    <div className="transfer-duration">
                                                                        {/* <Icon icon="ic:baseline-alarm" />
                                                                        Deposit in 1 min */}
                                                                        {val.deposit_est_time === "00:00:00" ? (
                                                                            <>
                                                                                <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                    {t("deposit.instant")}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Icon icon="ic:baseline-alarm" />
                                                                                    {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                            </>
                                                                        )}                                                                                     
                                                                    </div>                 
                                                                )}
                                                                </label>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })
                                            ) : (
                                                this.props.isOptionLoading === false ?
                                                <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                                <Skeleton />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t("deposit.choose-a-bank")}:</label>
                                    <div className="input-group-col">
                                        <div className={`form-check-group ${this.props.errorMsg.bank_id ? "form-control is-invalid" : ""}`}>

                                            {(this.props.input.paymentGatewaySelected === 'secret_pay_offline')
                                            ?
                                                (this.props.bankAccountOffline.length > 0)
                                                ?
                                                    (<>
                                                        {this.props.bankAccountOffline.map((val, index) => {
                                                            let bankColorImage =
                                                                this.props.bankImg[`${val.receiverBankCode.toLowerCase()}-color.svg`] !== undefined
                                                                    ? this.props.bankImg[`${val.receiverBankCode.toLowerCase()}-color.svg`]
                                                                    : this.props.bankImg["default-bank-icon-color.png"];

                                                            return (
                                                                <div className="form-check form-check-inline four-check" key={index}>
                                                                    <input
                                                                        className="btn-check"
                                                                        type="radio"
                                                                        name="bank_id"
                                                                        id={`bankOpt${val.receiverAccNo}`}
                                                                        value={val.receiverAccNo}
                                                                        onChange={(event) =>
                                                                            this.props.onInputChangeHandler(
                                                                                event,
                                                                                "secret_pay_offline",
                                                                                val.receiverAccName,
                                                                                val.bankAccountCodename,
                                                                                val.receiverAccNo
                                                                            )
                                                                        }
                                                                        checked={Number(this.props.input.bank_id) === Number(val.receiverAccNo) ? true : false}
                                                                    />
                                                                    <label className="btn form-check-label" htmlFor={`bankOpt${val.receiverAccNo}`}>
                                                                        <img src={bankColorImage} alt={val.icon} />
                                                                        <p>
                                                                            {(() => {
                                                                                switch (val.receiverBankCode) {
                                                                                    case "MBB":
                                                                                        return "Maybank";
                                                                                    case "HLB": 
                                                                                        return "Hong Leong";
                                                                                    case "CIMB":
                                                                                        return "CIMB Bank";
                                                                                    default:
                                                                                        return "RHB Bank";
                                                                                }
                                                                            })()}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </>)
                                                :
                                                (<span className="text-danger w-100">{t("deposit.invalid-bank")}</span>)
                                            :
                                            (this.props.input.paymentGatewaySelected === 'online_banking')
                                            ?
                                                (this.props.bankAccount.length > 0)
                                                ?
                                                    this.props.bankAccount.map((val, index) => {
                                                        let bankColorImage =
                                                            this.props.bankImg[`${val.swift_code.toLowerCase()}-color.svg`] !== undefined
                                                                ? this.props.bankImg[`${val.swift_code.toLowerCase()}-color.svg`]
                                                                : this.props.bankImg["default-bank-icon-color.png"];

                                                        return (
                                                            <div className="form-check form-check-inline four-check" key={index}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    name="bank_id"
                                                                    id={`bankOpt${val.bank_id}`}
                                                                    value={val.bank_id}
                                                                    onChange={(event) => this.props.onInputChangeHandler(event, "online_banking")}
                                                                    checked={Number(this.props.input.bank_id) === Number(val.bank_id) ? true : false}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`bankOpt${val.bank_id}`}>
                                                                    <img src={bankColorImage} alt={val.icon} />
                                                                    <p>{val.bank_name}</p>
                                                                </label>
                                                            </div>
                                                        );
                                                    })
                                                :
                                                (<span className="text-danger w-100">{t("deposit.invalid-bank")}</span>)
                                            :
                                                (<span className="text-danger w-100">{t("deposit.invalid-bank")}</span>)
                                            }
                                        </div>
                                        <div className="invalid-feedback">{this.props.errorMsg.bank_id || ""}</div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t("deposit.bank-account-number")}:</label>
                                    <div className="input-group-col">
                                        <div className="input-group right">
                                            {/* {this.props.bankAccountOffline[this.props.input.bank_id] && this.props.input.paymentGatewaySelected === 'secret_pay_offline' ? (
                                                <input
                                                    type="text"
                                                    id="bankAccName"
                                                    name="bankAccName"
                                                    className="form-control"
                                                    value={
                                                        this.props.bankAccountOffline[this.props.input.bank_id]
                                                            ? this.props.bankAccountOffline[this.props.input.bank_id].receiverAccName
                                                            : ""
                                                    }
                                                    ref={(c) => (this.copyBankName = c)}
                                                    readOnly
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    id="bankAccName"
                                                    name="bankAccName"
                                                    className="form-control"
                                                    value={this.props.bankAccount[this.props.input.bank_id] ? this.props.bankAccount[this.props.input.bank_id].bank_acc_name : ""}
                                                    ref={(c) => (this.copyBankName = c)}
                                                    readOnly
                                                />
                                            )} */}
                                            <input
                                                type="text"
                                                id="bankAccName"
                                                name="bankAccName"
                                                className="form-control"
                                                value={
                                                    (this.props.input.paymentGatewaySelected === 'online_banking')
                                                    ?
                                                        (this.props.bankAccount[this.props.input.bank_id] && this.props.bankAccount[this.props.input.bank_id].bank_acc_name) 
                                                        ? 
                                                            this.props.bankAccount[this.props.input.bank_id].bank_acc_name
                                                        :
                                                            ''
                                                    :
                                                        (this.props.input.paymentGatewaySelected === 'secret_pay_offline')
                                                        ?
                                                            (this.props.bankAccountOffline[this.props.input.bank_id] && this.props.bankAccountOffline[this.props.input.bank_id].receiverAccName) 
                                                            ? 
                                                                this.props.bankAccountOffline[this.props.input.bank_id].receiverAccName 
                                                            : 
                                                                ''
                                                        :
                                                            ''
                                                }
                                                ref={(c) => (this.copyBankName = c)}
                                                readOnly
                                            />
                                            <span className="input-group-text">
                                                {/* {
                                                    this.props.copyText === "bankAccName" && <button className="btn btn-gradient-blue show-copied" disabled>{t('global.copied')}</button>
                                                } */}
                                                <button
                                                    type="button"
                                                    data-tip={t("global.copied")}
                                                    data-event="click1 focus"
                                                    className="btn icon"
                                                    onClick={(e) => this.props.copyToClipboard(e, this.copyBankName)}
                                                >
                                                    <Icon icon="mdi:content-copy" />
                                                </button>
                                                <ReactTooltip globalEventOff="click1" className="custom-tooltip" place="left" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t("deposit.account-number")}:</label>
                                    <div className="input-group-col">
                                        <div className="input-group right">
                                            {/* {this.props.bankAccountOffline[this.props.input.bank_id] && this.props.input.paymentGatewaySelected === 'secret_pay_offline' ? (
                                                <input
                                                    type="number"
                                                    id="bankAccNo"
                                                    name="bankAccNo"
                                                    className="form-control"
                                                    value={
                                                        this.props.bankAccountOffline[this.props.input.bank_id]
                                                            ? this.props.bankAccountOffline[this.props.input.bank_id].receiverAccNo
                                                            : ""
                                                    }
                                                    ref={(c) => (this.copyBankNum = c)}
                                                    readOnly
                                                />
                                            ) : (
                                                <input
                                                    type="number"
                                                    id="bankAccNo"
                                                    name="bankAccNo"
                                                    className="form-control"
                                                    value={this.props.bankAccount[this.props.input.bank_id] ? this.props.bankAccount[this.props.input.bank_id].bank_acc_number : ""}
                                                    ref={(c) => (this.copyBankNum = c)}
                                                    readOnly
                                                />
                                            )} */}
                                            <input
                                                type="number"
                                                id="bankAccNo"
                                                name="bankAccNo"
                                                className="form-control"
                                                value={
                                                    (this.props.input.paymentGatewaySelected === 'online_banking')
                                                    ?
                                                        (this.props.bankAccount[this.props.input.bank_id] && this.props.bankAccount[this.props.input.bank_id].bank_acc_number) 
                                                        ? 
                                                        this.props.bankAccount[this.props.input.bank_id].bank_acc_number 
                                                        : 
                                                            ''
                                                    :
                                                        (this.props.input.paymentGatewaySelected === 'secret_pay_offline')
                                                        ?
                                                            (this.props.bankAccountOffline[this.props.input.bank_id] && this.props.bankAccountOffline[this.props.input.bank_id].receiverAccNo) 
                                                            ? 
                                                            this.props.bankAccountOffline[this.props.input.bank_id].receiverAccNo 
                                                            : 
                                                                ''
                                                        :
                                                            ''
                                                }
                                                ref={(c) => (this.copyBankNum = c)}
                                                readOnly
                                            />
                                            <span className="input-group-text">
                                                {/* {
                                                    this.props.copyText === "bankAccNo" && <button className="btn btn-gradient-blue show-copied" disabled>{t('global.copied')}</button>
                                                } */}
                                                <button
                                                    type="button"
                                                    data-tip={t("global.copied")}
                                                    data-event="click2 focus"
                                                    className="btn icon"
                                                    onClick={(e) => this.props.copyToClipboard(e, this.copyBankNum)}
                                                >
                                                    <Icon icon="mdi:content-copy" />
                                                </button>
                                                <ReactTooltip globalEventOff="click2" className="custom-tooltip" place="left" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                <label className="col-form-label">{t('deposit.reference-code')}:</label>
                                <div className="input-group-col">
                                    <div className="input-group right">
                                        <input
                                            type="string"
                                            id="referenceCode"
                                            name="referenceCode"
                                            className="form-control"
                                            value={this.props.input.reference_code}
                                            ref={c => this.copyRefCode = c}
                                            readOnly
                                        />
                                        <span className="input-group-text">
                                            {
                                                this.props.copyText === "referenceCode" && <button className="btn btn-gradient-blue" disabled>{t('global.copied')}</button>
                                            }
                                            <button type="button" className="btn icon" onClick={(e) => this.props.copyToClipboard(e, this.copyRefCode)}>
                                                <Icon icon="mdi:content-copy" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                                <div>
                                    <label className="col-form-label d-flex align-items-center">
                                        {t("deposit.promotion")}:
                                        {this.props.promoOpts && (
                                            <button className="btn btn-gradient-blue tnc-btn" type="button" onClick={this.props.toggleTncModalHandler}>
                                                {t("deposit.tnc-apply")}
                                            </button>
                                        )}
                                    </label>
                                    <div className="input-group-col">
                                        <div className="input-group right">
                                            {/* <input
                                                placeholder={t('history.select-promotion')}
                                                name="selectPromo"
                                                className="form-control"
                                                value={this.props.promoOpts}
                                                onChange={this.props.promoChangeHandler}
                                                disabled
                                            /> */}
                                            <p className="form-control lh-lg" onClick={this.toggleSelectRadio}>
                                                {this.props.promoOpts ? this.props.promoOpts.label : <>{t("history.select-promotion")}</>}
                                            </p>
                                            {this.props.promoOpts && (
                                                <span className="input-group-text">
                                                    <Icon icon="mdi:close-circle" onClick={this.props.promoChangeHandler} />
                                                </span>
                                            )}
                                            <span className="input-group-text" onClick={this.toggleSelectRadio}>
                                                <Icon icon="uil:angle-right" />
                                            </span>
                                        </div>
                                        <MobileSelectRadio
                                            name={"selectPromo"}
                                            toggleHandler={this.toggleSelectRadio}
                                            showSelectRadio={this.state.showSelectRadio}
                                            onChange={this.props.promoChangeHandler}
                                            options={this.props.promoArry}
                                            value={this.props.promoOpts && this.props.promoOpts.key !== null ? this.props.promoOpts.key : null}
                                        />
                                        {/* <ReactSelect
                                            classNamePrefix="react-select"
                                            placeholder={t('history.select-promotion')}
                                            name="selectPromo"
                                            options={this.props.promoArry}
                                            onChange={this.promoChangeHandler}
                                            value={this.props.promoOpts}
                                            isClearable={true}
                                        /> */}
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('transaction-type.bonus-code')}:</label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className="input-group left has-validation">
                                            <input 
                                                id="bonusCode"
                                                name="bonusCode"
                                                value={this.props.input.bonusCode}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.bonusCode || ''}
                                                autoComplete="off"
                                                type="text" 
                                                className="form-control" 
                                                placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label w-100 d-flex justify-content-between">
                                        {t("deposit.deposit-amount")}:
                                        {process.env.REACT_APP_ENV === "staging" && this.props.currentUser.country_id === 4 &&
                                            <span style={{color: "#4a89fc"}}>Satuan 50 = IDR 50.000</span>
                                        }
                                    </label>
                                    <div className="input-group-col d-grid gap-2">
                                        <div className={`input-group left has-validation ${this.props.currentUser.country_id === 4 && "indonesia" }`}>
                                            <span className="input-group-text">{this.props.currentUser.currency}</span>
                                            <NumberInput
                                                id="amount"
                                                name="amount"
                                                // className="form-control"
                                                className={`form-control ${this.props.errorMsg.amount && "was-validated is-invalid"}`}
                                                aria-label="Username"
                                                aria-describedby="depositAmtHelp"
                                                value={this.props.input.amount}
                                                placeholder={`${t("deposit.min-max-limit")} ${new Intl.NumberFormat("en-US", this.props.num_format).format(
                                                    this.props.groupSetting.min_deposit || 0.0
                                                )}/${new Intl.NumberFormat("en-US", this.props.num_format).format(this.props.groupSetting.max_deposit || 0.0)}`}
                                                onChange={(event) => this.props.onInputChangeHandler(event)}
                                                required={true}
                                                min={this.props.groupSetting.min_deposit || 0}
                                                max={this.props.groupSetting.max_deposit || 0}
                                                step="any"
                                                onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                minValueMessage={t("validation.amtMinValue", { 0: this.props.groupSetting.min_deposit })}
                                                maxValueMessage={t("validation.amtMaxValue", { 0: this.props.groupSetting.max_deposit })}
                                                requiredMessage={t("validation.required")}
                                                formError={this.props.formError}
                                                errorMsg={this.props.errorMsg.amount || ""}
                                            />
                                        </div>
                                        <div className="form-check-group deposit-amts">
                                            {depositAmts &&
                                                depositAmts.map((depositAmt, index) => (
                                                    <div className="form-check form-check-inline five-check" key={index}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            name="depositAmt"
                                                            id={`depositAmt${depositAmt.value}`}
                                                            value={depositAmt.value}
                                                            onChange={(event) => this.props.onInputChangeHandler(event)}
                                                            checked={Number(this.props.depositAmt) === Number(depositAmt.value) ? true : false}
                                                        />
                                                        <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                            {depositAmt.value}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                        <small>
                                            {t("deposit.last-deposit-amount")}:{" "}
                                            <span style={{ fontWeight: "600" }}>
                                                {this.props.currentUser.currency}
                                                {this.props.lastDeposit}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t("deposit.deposit-attachment-file")}:</label>
                                    <div className="input-group-col">
                                        <Dropzone
                                            maxFiles={1}
                                            accept={"image/*"}
                                            // maxSize={500000}
                                            onDrop={this.props.onDrop}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div className={`dropzone-container ${this.props.errorMsg.file ? "form-control is-invalid" : ""}`}>
                                                    {this.props.dropFiles && this.props.dropFiles.length ? (
                                                        <aside>
                                                            <ul>{this.props.dropFiles}</ul>
                                                        </aside>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div {...getRootProps({ className: "dropzone" })}>
                                                        <div>
                                                            <input {...getInputProps()} />
                                                            <Icon icon="mdi:upload" />
                                                            <p>{t("deposit.drop-file-msg-1")}</p>
                                                            <p>{t("deposit.drop-file-msg-3")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div className="invalid-feedback">{this.props.errorMsg.file || ""}</div>
                                    </div>
                                </div>
                                <button className="btn btn-gradient-blue mx-3" type="submit" value="Submit">
                                    {t("global.confirm")}
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        store: state.deposit.store,
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileBankTransfer));
