import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Miscellaneous
import Slider from "react-slick";
// import { showBanner } from "../../store/ducks/banners/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { getPromotionBanners } from "../../store/ducks/promos/actions";
import Skeleton from "react-loading-skeleton";
import { isDesktop } from "react-device-detect";

//Components
import PrizesPool from "../../components/Banner/PrizesPool";
import Announcement from "../../components/Announcement/Announcement";

//Icons
import { Icon } from "@iconify/react";
import { withNamespaces } from "react-i18next";

class Banner extends Component {

  Exclusiveness = (props) => {
    return (
      <div className={`col-3 exclusive-container 
        ${this.state.currentEvent === "cny" && "cny"}
        ${this.state.currentEvent === "raya" && "raya"}
      `}> 
        <div className="d-flex align-items-center exclusive-content">
          <img src={props.icon}/>
          <div>
            <p>{props.title}</p>
            <span>{props.label}</span>
          </div>          
        </div>
      </div>
    )
  }

  constructor(props) {
    super(props);
    this.state = {
      desktop_image_preview: [],
      desktop_image_name: [],
      desktop_image_link: [],
      category: "",
      lang_id: "",
      data: [],

      openModal: false,
      openDropDown: false,
      loading: true,

      // Switch Event Theme
      currentEvent: "",
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.show_banner_data !== this.props.show_banner_data) {
    //   if (nextProps.show_banner_data.data.responseCode === 200) {
    //     const {
    //       desktop_image_preview,
    //       desktop_image_name,
    //       desktop_image_link,
    //       category,
    //     } = this.state;

    //     nextProps.show_banner_data.data.data.map((item, i) => {
    //       desktop_image_preview[item] = item.desktop_image;
    //       desktop_image_name[item] = item.desktop_image_name;
    //       desktop_image_link[item] = item.desktop_image_link;
    //       return item;
    //     });

    //     this.setState({
    //       loading: false,
    //       desktop_image_preview,
    //       desktop_image_name,
    //       desktop_image_link,
    //       category,
    //     });
    //   } else {
    //     return false;
    //   }
    // }
    if (nextProps.promotion_banners_data !== this.props.promotion_banners_data) {
      if (nextProps.promotion_banners_data.responseCode === 200) {
        this.setState({ loading: false });
      } else {
        return false;
      }
    }
    return true;
  }

  fetchData() {
    // const showBannerData = {};
    // showBannerData.category = this.props.category;
    // this.props.showBanner(showBannerData);
    const getPromotionBannersData = { category_id: "" };
    this.props.getPromotionBanners(getPromotionBannersData);
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  redirectUrlHandler = (e, url) => {
    // e.preventDefault();
    window.location.replace(url);
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(require.context("../../assets/images/", false, /\.(png|jpe?g|svg|webp)$/));
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      fade: true,
      speed: 700,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: false,
      cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: false,
          },
        },
      ],
    };
    let defaultBanner;
    let bannerCount = 0;
    if (!this.state.loading) {
      let promotion_banners_data = this.props.promotion_banners_data.data.promotion_banners;
      defaultBanner = promotion_banners_data.map((banner, idx) => {

        banner.desktop_image_name = "";
        banner.mobile_image_name = "";

        bannerCount = bannerCount + 1;

        let url_id = banner.desktop_image_link
          ? banner.desktop_image_link.split("promos/")
          : null;

        if (banner.redirect_url) {
          return (
            <Link
              to={{ pathname: banner.redirect_url }}
              key={idx}
              onClick={(event) =>
                this.redirectUrlHandler(event, banner.redirect_url)
              }
            >
              {isDesktop ? (
                <img
                  src={banner.desktop_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              ) : (
                <img
                  src={banner.mobile_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              )}
            </Link>
          );
        } else if (banner.id) {
          return (
            <Link
              to={{
                pathname: "/promos-detail/" + banner.id,
                params: { promo_id: banner.id },
              }}
              key={idx}
            >
              {isDesktop ? (
                <img
                  src={banner.desktop_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              ) : (
                <img
                  src={banner.mobile_image}
                  alt={banner.desktop_image_name}
                  effect="blur"
                />
              )}
            </Link>
          );
        } else {
          // display plain banner image without the possibility to redirect elsewhere
          return (
            <img
              src={isDesktop ? banner.desktop_image : banner.mobile_image}
              alt={banner.desktop_image_name}
              key={idx}
              effect="blur"
            />
          );
        }
      });
    }

    return (
      <Auxiliary>
        <div className="banner-slick">
          <div className="slick-container">
            {this.state.loading ? (
              <Skeleton
                count={1}
                height="100%"
                style={{ lineHeight: "unset" }}
              />
            ) : (
              <>
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                  {defaultBanner}
                </Slider>
                { window.location.pathname === "/slot" ||
                window.location.pathname.includes("/slot") && isDesktop && 
                  <PrizesPool />
               }
                {bannerCount > 1 && isDesktop && (
                  <div className="slick-arrow">
                    <div className="slick-arrow-btn prev" onClick={() => this.slider.slickPrev()}>
                        <Icon icon="uil:angle-left-b" />
                    </div>
                    <div className="slick-arrow-btn next" onClick={() => this.slider.slickNext()}>
                        <Icon icon="uil:angle-right-b" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Announcement />
        {isDesktop && (
          // <img class="d-block mt-4 mx-auto" src={img["asset-1.svg"]} alt="" />
          // <div className="x-container d-flex justify-content-evenly mt-4 p-0">
          <div className="x-container mt-4 p-0 row">
            <this.Exclusiveness 
              icon={img["promo-given.webp"]}
              title="RM 288,888,888"
              label={t('main-page.promos-give-out')}
            />
            <this.Exclusiveness 
              icon={img["deposit.webp"]}
              title={" 2 " + t('main-page.minutes')}
              label={t('main-page.avg-time-deposit')}
            />
            <this.Exclusiveness 
              icon={img["withdrawal.webp"]}
              title={" 10 " + t('main-page.minutes')}
              label={t('main-page.avg-time-withdrawal')}              
            />
            <this.Exclusiveness 
              icon={img["winner.webp"]}
              title={t('main-page.winner-board')}
              label={t('main-page.deposit-win')}
            />
          </div>
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { promos } = state;
  return {
    promotion_banners_data: promos.promotion_banners_data,
    currentUser: state.currentUser.data,
  };
};

const mapDispatchToProps = {
  getUserData,
  getPromotionBanners
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Banner));
