import * as type from './types';
import axios from '../../../common/config/axios';

//Call the api for example call the login api
export const getPromos = (res_data) => (dispatch, getState) => {
    res_data.country_id = getState().currentUser.data.country_id;
    res_data.member_group_id = localStorage.getItem("accessToken") ? (getState().currentUser.data.group_id ?? "") : "";
    res_data.lang_id = getState().currentUser.data.language_id ?? localStorage.getItem('lang_id');
    axios.get('promotion/get_promotion',  {
        params: res_data
    }).then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.GET_PROMOS,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GET_PROMOS,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const showPromos = (res_data) => (dispatch, getState) => {
    res_data.country_id = getState().currentUser.data.country_id;
    if(res_data.lang_id === null || res_data.lang_id === undefined || res_data.lang_id <= 0){ // check if value empty due to native ios app
        res_data.lang_id = getState().currentUser.data.language_id;
    }

    // res_data.lang_id = getState().currentUser.data.language_id ?? localStorage.getItem('lang_id');
    axios.get('promotion/show',  {
        params: res_data
    }).then( (response) => {
            //Dispatch to the reducers
            dispatch ({
                //Type is based on types.js name you created
                type: type.SHOW_PROMOS,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.SHOW_PROMOS,
            // payload: {"rst":"0","msg":"system_error"}
            payload: err
        })
    })
};

export const setPersistState = (data) => dispatch =>
{
    if(data)
    {
        dispatch({
            type: type.SET_STATE,
            payload: data,
        });
    }
};

export const getPromotionBanners = (res_data) => (dispatch, getState) => {
    res_data.country_id = getState().currentUser.data.country_id;
    res_data.member_group_id = localStorage.getItem("accessToken") ? (getState().currentUser.data.group_id ?? "") : "";
    res_data.lang_id = getState().currentUser.data.language_id ?? localStorage.getItem('lang_id');
    axios.get('promotion/get_promotion_banners',  {
        params: res_data
    }).then( (response) => {
            dispatch ({
                type: type.GET_PROMOTION_BANNERS,
                payload: response.data
            })
        }).catch (err => {
        dispatch ({
            type: type.GET_PROMOTION_BANNERS,
            payload: err
        })
    })
};

